export class PodcastPlayer {
  constructor() {
    if (window.podcastPlayerInitialized) {
      return window.podcastPlayer;
    }

    this.audio = new Audio();
    this.isPlaying = false;
    this.currentSpeed = 1;

    this.tabId = sessionStorage.getItem('podcastPlayerTabId') || Math.random().toString(36).substring(7);
    sessionStorage.setItem('podcastPlayerTabId', this.tabId);

    this.togglePlay = this.togglePlay.bind(this);
    this.toggleMute = this.toggleMute.bind(this);
    this.seek = this.seek.bind(this);
    this.updateProgress = this.updateProgress.bind(this);
    this.toggleSpeed = this.toggleSpeed.bind(this);
    this.close = this.close.bind(this);

    document.addEventListener('click', (e) => {
      if (e.target.matches('.close-button')) {
        e.preventDefault();
        e.stopPropagation();
        this.close();
      }
    });

    window.podcastPlayerInitialized = true;
    window.podcastPlayer = this;

    this.setupStateSaving();
    window.addEventListener('popstate', this.popstateHandler);
  }

  initialize() {
    this.initializeElements();
    this.setupEventListeners();
  }

  initializeElements() {
    this.playPauseBtn = document.getElementById('playPauseBtn');
    this.playIcon = this.playPauseBtn?.querySelector('.play-icon');
    this.pauseIcon = this.playPauseBtn?.querySelector('.pause-icon');
    this.speedBtn = document.getElementById('speedBtn');
    this.volumeBtn = document.getElementById('volumeBtn');
    this.closeBtn = document.querySelector('.close-button');

    this.progressBar = document.querySelector('.progress-bar');
    this.progress = document.querySelector('.progress');
    this.timeDisplay = document.getElementById('currentTime');

    this.episodeTitle = document.getElementById('episodeTitle');
  }

  loadEpisode(episodeData) {
    const savedState = localStorage.getItem('podcastPlayerState');
    let savedPosition = 0;

    if (savedState) {
      const state = JSON.parse(savedState);
      if (state.audioUrl === episodeData.audioUrl) {
        savedPosition = state.currentTime || 0;
      }
    }

    this.audio.src = episodeData.audioUrl;
    this.audio.currentTime = savedPosition;

    if (this.episodeTitle) {
      this.episodeTitle.textContent = episodeData.title;
    }
    document.querySelector('.rnk-podcastPlayer').classList.remove('hidden');
  }

  popstateHandler = () => {
    const currentTitle = this.episodeTitle?.textContent;

    setTimeout(() => {
      const player = document.querySelector('.rnk-podcastPlayer');
      if (!player) this.createPlayerHTML();

      this.initialize();

      if (currentTitle && this.episodeTitle) {
        this.episodeTitle.textContent = currentTitle;
      }

      if (this.audio.src && !this.audio.paused) {
        document.querySelector('.rnk-podcastPlayer')?.classList.remove('hidden');
        this.updatePlayPauseButton();
      }
    }, 0);
  }

  setupStateSaving() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden' && this.isPlaying) {
        this.saveState();
      }
    });

    window.addEventListener('beforeunload', () => {
      if (this.isPlaying) {
        this.saveState();
      }
    });
  }

  saveState() {
    if (this.audio.src) {
      const isEnded = Math.abs(this.audio.currentTime - this.audio.duration) < 0.5; // allowing 0.5 second threshold

      const state = {
        audioUrl: this.audio.src,
        // If audio ended, reset position to 0
        currentTime: isEnded ? 0 : this.audio.currentTime,
        playbackRate: this.currentSpeed,
        episodeTitle: this.episodeTitle?.textContent || '',
        isPlaying: !this.audio.paused,
        wasManuallyClosed: this.wasManuallyClosed || false
      };
      localStorage.setItem('podcastPlayerState', JSON.stringify(state));
    }
  }

  loadSavedState() {
    try {
      const savedState = localStorage.getItem('podcastPlayerState');
      if (savedState) {
        const state = JSON.parse(savedState);

        if (state.audioUrl && !state.wasManuallyClosed) {
          if (!document.querySelector('.rnk-podcastPlayer')) {
            this.createPlayerHTML();
            this.initialize();
          }

          this.audio.src = state.audioUrl;
          this.audio.currentTime = state.currentTime || 0;
          this.currentSpeed = state.playbackRate || 1;
          this.audio.playbackRate = this.currentSpeed;

          if (this.episodeTitle) {
            this.episodeTitle.textContent = state.episodeTitle || '';
          }
          if (this.speedBtn) {
            this.speedBtn.textContent = `${this.currentSpeed}x`;
          }

          document.querySelector('.rnk-podcastPlayer').classList.remove('hidden');
          this.updateProgress();

          if (state.isPlaying) {
            this.play();
          }
        }
      }
    } catch (error) {
      console.error('Error loading saved state:', error);
      this.clearState();
    }
  }

  clearState() {
    localStorage.removeItem('podcastPlayerState');
    this.wasManuallyClosed = false;
  }

  createPlayerHTML() {
    const playerHTML = `
      <div class="rnk-podcastPlayer hidden" data-play-button-target="player">
        <div class="player-header">
          <h2 class="podcast-title">
            ESCUCHANDO "
            <span id="episodeTitle"></span>
            "
          </h2>
          <button class="close-button"></button>
        </div>
        <div class="player-controls">
          <button id="playPauseBtn" class="control-button">
            <span class="play-icon">▶</span>
            <span class="pause-icon hidden">⏸</span>
          </button>
          <div class="progress-container">
            <div class="progress-bar">
              <div class="progress"></div>
            </div>
            <span id="currentTime">00:00</span>
          </div>
          <div class="speed-control">
            <button id="speedBtn">1x</button>
          </div>
          <button id="volumeBtn" class="volume-button on"></button>
        </div>
      </div>
    `
    document.body.insertAdjacentHTML('beforeend', playerHTML)
  }

  setupEventListeners() {
    if (this.playPauseBtn) {
      this.playPauseBtn.onclick = null;
      this.playPauseBtn.onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.togglePlay();
      };
    }

    if (this.volumeBtn) {
      this.volumeBtn.onclick = null;
      this.volumeBtn.onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.toggleMute();
      };
    }

    if (this.speedBtn) {
      this.speedBtn.onclick = null;
      this.speedBtn.onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.toggleSpeed();
      };
    }

    if (this.progressBar) {
      this.progressBar.onclick = null;
      this.progressBar.onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.seek(e);
      };
    }

    this.audio.ontimeupdate = this.updateProgress;
    this.audio.onloadedmetadata = this.updateProgress;
  }

  close() {
    this.audio.pause();
    this.wasManuallyClosed = true;
    this.isPlaying = false;
    document.querySelector('.rnk-podcastPlayer').classList.add('hidden');
    this.updatePlayPauseButton();
    this.saveState();
  }

  togglePlay() {
    if (this.audio.src) {
      if (this.isPlaying) {
        this.audio.pause();
        this.isPlaying = false;
        this.saveState();
      } else {
        this.audio.play();
        this.isPlaying = true;
      }
      this.updatePlayPauseButton();
    }
  }

  play() {
    if (this.audio.src) {
      const savedState = localStorage.getItem('podcastPlayerState');
      if (savedState) {
        const state = JSON.parse(savedState);
        if (state.audioUrl === this.audio.src && state.currentTime) {
          this.audio.currentTime = state.currentTime;
        }
      }
      this.audio.play();
      this.isPlaying = true;
      this.updatePlayPauseButton();
      document.querySelector('.rnk-podcastPlayer').classList.remove('hidden');
    }
  }

  updatePlayPauseButton() {
    if (this.playIcon && this.pauseIcon) {
      if (this.isPlaying) {
        this.playIcon.classList.add('hidden');
        this.pauseIcon.classList.remove('hidden');
      } else {
        this.playIcon.classList.remove('hidden');
        this.pauseIcon.classList.add('hidden');
      }
    }
  }

  toggleSpeed() {
    const speeds = [1, 1.5, 2, 0.5];
    const currentIndex = speeds.indexOf(this.currentSpeed);
    this.currentSpeed = speeds[(currentIndex + 1) % speeds.length];
    this.audio.playbackRate = this.currentSpeed;
    if (this.speedBtn) {
      this.speedBtn.textContent = `${this.currentSpeed}x`;
    }
  }

  toggleMute() {
    this.audio.muted = !this.audio.muted;
    if (this.volumeBtn) {
      this.volumeBtn.classList.toggle('off', this.audio.muted);
      this.volumeBtn.classList.toggle('on', !this.audio.muted);
    }
  }

  seek(event) {
    if (this.progressBar && this.audio.duration) {
      const rect = this.progressBar.getBoundingClientRect();
      const percent = (event.clientX - rect.left) / rect.width;
      this.audio.currentTime = percent * this.audio.duration;
    }
  }

  updateProgress() {
    if (this.progress && this.timeDisplay && this.audio.duration) {
      const percent = (this.audio.currentTime / this.audio.duration) * 100;
      this.progress.style.width = `${percent}%`;
      this.timeDisplay.textContent = this.formatTime(this.audio.currentTime);
    }
  }

  formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    seconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
}

const savedState = localStorage.getItem('podcastPlayerState');
if (savedState && !window.podcastPlayer) {
  window.podcastPlayer = new PodcastPlayer();
  window.podcastPlayer.loadSavedState();
}
