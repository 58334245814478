import { Controller } from '@hotwired/stimulus'
import { PodcastPlayer } from '../packs/podcast-player'

export default class extends Controller {
  static values = {
    audioUrl: String,
    episodeTitle: String,
    episodeId: String,
    podcastTitle: String,
    buttonText: { type: String, default: 'Escuchar' },
    playingText: { type: String, default: 'Escuchando' }
  }

  static targets = ['player']

  connect() {
    window.podcastPlayer = window.podcastPlayer || new PodcastPlayer()

    document.addEventListener('click', (e) => {
      if (e.target.matches('.close-button')) {
        this.close()
      }
    })

    this.boundDisableRelatedButtons = this.disableRelatedButtons.bind(this)

    document.addEventListener('turbo:load', this.boundDisableRelatedButtons)
    document.addEventListener('popstate', this.boundDisableRelatedButtons)
  }

  play() {
    const player = window.podcastPlayer;

    if (!document.querySelector('.rnk-podcastPlayer')) {
      player.createPlayerHTML();
      player.initialize();
    }

    if (player.audio.src !== this.audioUrlValue) {
      const episodeData = {
        audioUrl: this.audioUrlValue,
        title: this.episodeTitleValue
      };
      player.loadEpisode(episodeData);
    }

    player.play();

    this.resetAllButtons()
    this.disableRelatedButtons();
  }

  close() {
    const player = window.podcastPlayer;
    if (player) {
      player.audio.pause();
      document.querySelector('.rnk-podcastPlayer').classList.add('hidden');
      this.resetAllButtons();
    }
  }

  disableRelatedButtons() {
    if (window.podcastPlayer && window.podcastPlayer.isPlaying) {
      document.querySelectorAll(`[data-play-button-audio-url-value="${window.podcastPlayer.audio.src}"]`)
        .forEach(element => {
          const currentHtml = element.innerHTML
          element.innerHTML = currentHtml.replace(this.buttonTextValue, this.playingTextValue)
          element.setAttribute('disabled', '')
          element.classList.add('disabled')
        })
    }
  }

  resetAllButtons() {
    document.querySelectorAll('[data-controller="play-button"]').forEach(element => {
      const currentHtml = element.innerHTML
      element.innerHTML = currentHtml.replace(this.playingTextValue, this.buttonTextValue)
      element.removeAttribute('disabled')
      element.classList.remove('disabled')
    })
  }

  updateButtonText(newText) {
    const currentHtml = this.element.innerHTML
    this.element.innerHTML = currentHtml.replace('Escuchar', newText)
    this.element.setAttribute('disabled', '')
    this.element.classList.add('disabled')
  }

  stop() {
    document.removeEventListener('turbo:load', this.boundDisableRelatedButtons)
    this.resetAllButtons()
  }
}
