import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.updateSubmitButton()
    this.element.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
      checkbox.addEventListener('change', this.updateLabelStyle)
      checkbox.addEventListener('change', () => this.updateSubmitButton())
    })
  }

  updateSubmitButton() {
    const checkboxes = this.element.querySelectorAll('input[type="checkbox"]')
    const allChecked = Array.from(checkboxes).every(checkbox => checkbox.checked)
    const submitButton = this.element.querySelector('input[type="submit"]')
    submitButton.disabled = allChecked
  }

  updateLabelStyle(event) {
    const span = event.target.closest('label').querySelector('span')
    span.classList.toggle('rnk-text-decoration-line-through', !event.target.checked)
  }

  desmarcaTodas(event) {
    event.preventDefault()
    const submitButton = this.element.querySelector('input[type="submit"]')
    submitButton.disabled = false
    this.element.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
      checkbox.checked = false
      this.updateLabelStyle({ target: checkbox })
    })
  }

  toggleSections(event) {
    event.preventDefault()
    document.getElementById('ofrece-ver-otras-suscripciones').style.display = 'none'
    document.getElementById('otras-suscripciones').classList.add('is-visible')
  }

  validateForm(event) {
    const checkedBoxes = this.element.querySelectorAll('input[type="checkbox"]:checked')
    if (checkedBoxes.length === 0) {
      if (!confirm('¿Estas seguro de dar de baja todas tus suscripciones?')) {
        event.preventDefault()
        // Re-habilitar el botón submit
        const submitButton = this.element.querySelector('input[type="submit"]')
        submitButton.disabled = false
      }
    }
  }
}
