import { Turbo } from "@hotwired/turbo-rails"
import "../controllers"

Turbo.session.drive = false

document.addEventListener('turbo:click', (event) => {
  if (event.target.getAttribute('href').includes('#')) {
    Turbo.session.navigator.__proto__.url = event.target.getAttribute('href')
  }

  event.preventDefault()

  Turbo.session.adapter.progressBar.setValue(0)
  Turbo.session.adapter.progressBar.show()
})

document.addEventListener('turbo:frame-load', async (event) => {
  let url_with_anchor = Turbo.session.navigator.__proto__.url

  if (url_with_anchor !=null && url_with_anchor.includes('#')) {
    Turbo.session.navigator.__proto__.url = null
    window.location.href = url_with_anchor
  } else {
    event.preventDefault()
    window.scrollTo(0, 0)
  }
})


// Solución temporal para el problema de navegación en enlaces a foros
// Este código intercepta los clics en enlaces a temas de foros específicos
// y fuerza una navegación directa para evitar que sean redirigidos incorrectamente a /bolsa
// https://trello.com/c/Eh0xtG4g

// IMPORTANTE: Este código debe permanecer en este archivo para funcionar correctamente.
// Soluciona un problema de navegación donde los enlaces a foros son redirigidos incorrectamente a /bolsa.
// Intentar moverlo a un archivo separado hace que deje de funcionar.
document.addEventListener('DOMContentLoaded', function() {
  // Interceptar TODOS los clics en enlaces a foros
  document.addEventListener('click', function(event) {
    // Buscar si el clic fue en un enlace o dentro de un enlace
    const link = event.target.closest('a');

    // Si es un enlace a foros específicos que queremos proteger
    if (link && link.href && link.href.includes('/foros/bolsa/temas/')) {
      console.log('Interceptando clic en enlace a foro:', link.href);

      // Prevenir cualquier manejo predeterminado y propagación
      event.preventDefault();
      event.stopImmediatePropagation();

      // Forzar una navegación completa
      const targetUrl = link.href;
      console.log('Forzando navegación a:', targetUrl);

      // Usar setTimeout para asegurar que esto ocurra después de cualquier otro código
      setTimeout(function() {
        // Desactivar temporalmente cualquier listener de history/popstate que pudiera interferir
        const originalAddEventListener = window.addEventListener;
        window.addEventListener = function(type) {
          if (type === 'popstate' || type === 'beforeunload') {
            console.log('Bloqueando listener temporal de:', type);
            return;
          }
          return originalAddEventListener.apply(this, arguments);
        };

        // Navegación forzada
        window.location.href = targetUrl;

        // Restaurar addEventListener después de un breve retraso
        setTimeout(function() {
          window.addEventListener = originalAddEventListener;
        }, 100);
      }, 0);
    }
  }, true); // Usar fase de captura
});
